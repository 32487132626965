import { replaceVulvaDesign } from "../state/app"

/**
 * A machine that takes a vulva builder catalog and a
 * state object and turns them into a map of
 * actual images to display
 * @param state
 * @param catalog
 * @returns {{}}
 */
export const generateImageMap = (state, catalog) => {
  return Object.entries(state).reduce((acc, [type, index]) => {
    acc[type] = catalog[type].variants[index]
    acc[type].zIndex = catalog[type].zIndex
    return acc
  }, {})
}

/**
 * Returns a random array index from an array
 * @param array
 * @returns {number}
 */
export const pickRandom = array => Math.floor(Math.random() * array.length)

/**
 * Reducer function to return a random item from a
 * [key, value] version of the vulva builder catalot
 * @param accumulator
 * @param currentValue
 * @returns {*}
 */
export function randomVulvaReducer(accumulator, currentValue) {
  const label = currentValue[0]
  const variants = Object.keys(currentValue[1].variants)

  accumulator[label] = variants[pickRandom(variants)]
  return accumulator
}

/**
 * Helper function to generate and dispatch a random vulva to
 * the Redux store
 * @param dispatch
 * @param catalog
 */
export function generateRandomVulva(dispatch, catalog) {
  const randomVulva = Object.entries(catalog).reduce(randomVulvaReducer, {})
  dispatch(replaceVulvaDesign(randomVulva))
}

