import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import styled from "@emotion/styled"
import { toast } from "react-toastify"

import useFirebase from "../../hooks/useFirebase"
import Layout from "../../components/global/layout"
import Vulva from "../../components/builder/Vulva"
import ReelRow from "../../components/builder/ReelRow"
import { updateVulvaDesign } from "../../state/app"
import Button from "../../components/form/Button"
import {
  generateImageMap,
  generateRandomVulva,
} from "../../utils/vulvaBuilderUtils"
import { uuid } from "../../utils/uuid"
import { vulvaBuilderCatalog as catalog } from "../../data/vulva-maker"
import { urls } from "../../data/urls"
import { VulvaPreloader } from "../../components/builder/Preloader"

import Random from "../../images/utility/random.svg"

const PageLayout = styled.div`
  display: grid;
  grid-template-rows: ${props =>
    props.fullscreen ? "1fr max-content" : "60% 1fr"};
  height: 100%;
`
const Rows = styled.div`
  row-gap: 16px;
  display: ${props => (props.fullscreen ? "none" : "block")};
`
const Overflow = styled.div`
  border-radius: 8px 8px 0 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 10;
  box-shadow: ${props =>
    props.fullscreen ? "none" : "0px 0px 30px rgba(0, 0, 0, 0.2)"};
  padding: ${props => (props.fullscreen ? "0" : "32px 0 16px")};
`
const ImageContainer = styled.div`
  position: relative;
`
const Subheader = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-weight: 600;
`
const ButtonContainer = styled.div`
  display: grid;
  padding: ${props => (props.fullscreen ? "0  16px 32px 16px" : "32px 16px")};
  gap: 16px;
`
const Shuffle = styled.button`
  position: absolute;
  bottom: 24px;
  left: 24px;
  background: transparent;
  border: none;
  z-index: 10;
  background: url(${Random});
  width: 48px;
  height: 48px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  display: ${props => (props.fullscreen ? "none" : "block")};
  mix-blend-mode: exclusion;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  &:focus {
    outline: none;
  }
`

export default function VulvaBuilderPage() {
  const documentReference = useSelector(state => state.app.firebaseDocumentId)
  const [fullscreen, setFullscreen] = useState(false)

  const dispatch = useDispatch()
  const firebase = useFirebase()

  const state = useSelector(state => state.app.vulva)

  function setPart(part) {
    return function (variant) {
      dispatch(updateVulvaDesign([part, variant]))
    }
  }

  async function submitDesign(documentReference, vulvaState) {
    let docRef = documentReference

    if (!documentReference) {
      try {
        const doc = await firebase
          .firestore()
          .collection("userData")
          .add({ lastUpdated: Date.now() })
        docRef = doc.id
      } catch (error) {
        console.error("Error adding document: ", error)
        toast.error("An error has occured.", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: 2500,
        })
      }

      try {
        const data = {
          lastUpdated: Date.now(),
          vulvaMaker: {
            dateCompleted: Date.now(),
            choices: vulvaState,
          },
        }

        await firebase
          .firestore()
          .collection("userData")
          .doc(docRef)
          .set(data, { merge: true })
      } catch (error) {
        console.error("Error adding document: ", error)
        toast.error("An error has occurred.", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: 2500,
        })
      }
    }
  }

  const imageMap = generateImageMap(state, catalog)
  return (
    <Layout title={fullscreen ? "Finished" : "Make your own vulva"} marginless>
      <VulvaPreloader />
      <PageLayout fullscreen={fullscreen}>
        <ImageContainer>
          <Vulva images={imageMap} />
          <Shuffle
            onClick={() => generateRandomVulva(dispatch, catalog)}
            fullscreen={fullscreen}
          />
        </ImageContainer>
        <Overflow fullscreen={fullscreen}>
          <Rows fullscreen={fullscreen}>
            {Object.entries(catalog)
              .filter(item => Object.keys(item[1].variants).length > 1)
              .map(function (row, i) {
                const part = row[0]
                const catalogName = row[1].title
                const catalogList = row[1].variants
                return (
                  <React.Fragment key={uuid(`${row[1].title}-${i}`)}>
                    <ReelRow
                      title={catalogName}
                      list={catalogList}
                      items
                      updater={setPart(part)}
                      activeItem={state[part]}
                    />
                  </React.Fragment>
                )
              })}
          </Rows>
          <ButtonContainer fullscreen={fullscreen}>
            {!fullscreen && (
              <Button
                backgroundColor={props => props.theme.rose}
                onClick={() => setFullscreen(true)}
              >
                Finished?
              </Button>
            )}
            {fullscreen && (
              <>
                <Subheader>Happy with your vulva?</Subheader>
                <Button
                  backgroundColor={props => props.theme.rose}
                  buttonStyle={"inverted"}
                  onClick={() => {
                    submitDesign(documentReference, state).finally(() => {
                      navigate(urls.VM.FINISH)
                    })
                  }}
                >
                  Yes
                </Button>
                <Button
                  backgroundColor={props => props.theme.rose}
                  buttonStyle={"inverted"}
                  onClick={() => setFullscreen(false)}
                >
                  No, I'm still editing
                </Button>
              </>
            )}
          </ButtonContainer>
        </Overflow>
      </PageLayout>
    </Layout>
  )
}
