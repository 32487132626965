import React from "react"
import styled from "@emotion/styled"

const Dot = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  background: ${props => props.theme.apricot};
  border-radius: 50%;
  opacity: 0;
  transform: scale3d(0, 0, 0);
  will-change: opacity, transform;
  &.--visible {
    animation: enter 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  @keyframes enter {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
`

export default function Selected({ visible }) {
  return <Dot className={visible ? "--visible" : ""} />
}
