import styled from "@emotion/styled"
import React from "react"
import Selected from "./Selected"

const Container = styled.button`
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  background: none;
  border: 0;
  padding: 0;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.1s linear;
  will-change: transform;
  &:active,
  &:focus-within {
    transform: translate3d(0, 2px, 0) scale(0.95);
    background: none;
    outline: none;
  }
`
const Card = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${props => props.theme.shade[100]};
  background-image: url(${props => props.image});
  background-position: center;
  background-size: 100%;
  border-radius: 8px;
  position: relative;
  @media (max-width: 350px) {
    height: 60px;
    width: 60px;
  }
`

const Label = styled.p`
  text-align: center;
  color: ${props => props.theme.wine};
  margin: 8px 0 0 0;
  pointer-events: none;
  @media (max-width: 350px) {
    font-size: 0.8em;
  }
`

export default function Box({ image, title, selected, index, updater }) {
  return (
    <Container
      onClick={() => {
        updater(index)
      }}
    >
      <Card image={`/images/vulva-maker${image}`}>
        <Selected visible={selected} />
      </Card>
      <Label>{title}</Label>
    </Container>
  )
}
