import styled from "@emotion/styled"
import React, { useLayoutEffect, useRef, useState } from "react"
import { uuid } from "../../utils/uuid"
import ArrowLeft from "./ArrowLeft"
import ArrowRight from "./ArrowRight"
import Box from "./Box"

const Container = styled.div`
  overflow: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  display: ${props => (props.fullscreen ? "none" : "block")};
`
const Title = styled.h4`
  margin-left: 17px;
  margin-bottom: 0;
  color: ${props => props.theme.rose};
`
const Overflow = styled.div`
  padding: 8px 0;
  position: relative;
  /* If screen having overflow issues start here */
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`
const Row = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-start;
  &:before {
    content: "";
    padding: 1px;
  }
  &:after {
    content: "";
    padding: 1px;
  }
`

export default function ReelRow({
  title,
  list,
  updater,
  activeItem,
  fullscreen,
}) {
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)

  const overflowRef = useRef(null)
  const rowRef = useRef(null)

  useLayoutEffect(() => {
    const overflowElement = overflowRef.current

    function checkArrows() {
      const overflowExactWidth = overflowElement.offsetWidth
      const overflowScrollWidth = overflowElement.scrollWidth
      const overflowLeftScroll = overflowElement.scrollLeft

      if (overflowLeftScroll > 0) {
        setIsAtStart(false)
      } else {
        setIsAtStart(true)
      }
      if (overflowScrollWidth === overflowExactWidth + overflowLeftScroll) {
        setIsAtEnd(true)
      } else {
        setIsAtEnd(false)
      }
    }

    checkArrows()
    overflowElement.addEventListener("scroll", checkArrows)

    return function () {
      overflowElement.removeEventListener("scroll", checkArrows)
    }
  })

  const onNextClick = () => {
    const { scrollLeft } = overflowRef.current

    overflowRef.current.scroll({
      left: scrollLeft + 100,
      behavior: "smooth",
    })
  }

  const onPrevClick = () => {
    const { scrollLeft } = overflowRef.current

    overflowRef.current.scroll({
      left: scrollLeft - 100,
      behavior: "smooth",
    })
  }

  return (
    <Container fullscreen={fullscreen}>
      <Title>{title}</Title>
      <ArrowLeft isAtStart={!isAtStart} onClick={onPrevClick} />
      <ArrowRight isAtEnd={!isAtEnd} onClick={onNextClick} />
      <Overflow ref={overflowRef}>
        <Row ref={rowRef}>
          {Object.entries(list).map(function (row) {
            return (
              <Box
                key={uuid(`${title}-${row[1].title}`)}
                image={row[1].thumbnail}
                title={row[1].title}
                selected={row[0] === activeItem}
                index={row[0]}
                updater={updater}
              />
            )
          })}
        </Row>
      </Overflow>
    </Container>
  )
}
