import styled from "@emotion/styled"
import React from "react"
import LeftArrowIcon from "../../images/utility/arrow-left.svg"

const LeftArrow = styled.button`
  border: 0;
  position: absolute;
  background-color: #ffffff;
  background-image: url(${LeftArrowIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 48px;
  height: 48px;
  margin: auto;
  left: 16px;
  right: auto;
  top: 0;
  bottom: 14px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  will-change: transform, opacity;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  &.--visible {
    animation-name: fadeScaleIn;
  }
  &.--hidden {
    animation-name: fadeScaleOut;
  }

  @keyframes fadeScaleIn {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeScaleOut {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }
`

export default function ArrowLeft({ isAtStart, onClick }) {
  return (
    <LeftArrow
      className={isAtStart ? "--visible" : "--hidden"}
      onClick={onClick}
    />
  )
}
