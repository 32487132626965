import React from "react"
import styled from "@emotion/styled"
import ImageLayer from "./ImageLayer"
import { uuid } from "../../utils/uuid"

const Container = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`
const ImageFigure = styled.figure`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: ${props => (props.zindex ? props.zindex : "unset")};
`

export default function Vulva({ images }) {
  return (
    <Container>
      {Object.entries(images).map(function (row, i) {
        return (
          <ImageFigure key={uuid(`image-${row[0]}-${i}`)}>
            <ImageLayer image={row[1].image} highlight={row[1].highlight} />
          </ImageFigure>
        )
      })}
    </Container>
  )
}
