import styled from "@emotion/styled"
import React from "react"
import RightArrowIcon from "../../images/utility/arrow-right.svg"

const RightArrow = styled.button`
  border: 0;
  position: absolute;
  background-color: #ffffff;
  background-image: url(${RightArrowIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 48px;
  height: 48px;
  margin: auto;
  left: auto;
  right: 16px;
  top: 0;
  bottom: 14px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  will-change: transform, opacity;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  &.--visible {
    animation-name: fadeScaleIn;
  }
  &.--hidden {
    animation-name: fadeScaleOut;
  }

  @keyframes fadeScaleIn {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeScaleOut {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }
`

export default function ArrowRight({ isAtEnd, onClick }) {
  return (
    <RightArrow
      className={isAtEnd ? "--visible" : "--hidden"}
      onClick={onClick}
    />
  )
}
